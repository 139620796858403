'use client'

import { useTheme } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'
import { FC, useEffect, useState } from 'react'
import { Toaster as ToasterComponent } from 'react-hot-toast'

export const Toaster: FC = () => {
  const { breakpoints } = useTheme()
  const isSmallDevice = useMediaQuery(breakpoints.down('sm'))
  const [isClient, setIsClient] = useState(false)

  useEffect(() => {
    setIsClient(true)
  }, [])

  if (!isClient) {
    return undefined
  }

  return (
    <ToasterComponent position={isSmallDevice ? 'top-right' : 'bottom-right'} />
  )
}
