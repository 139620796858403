import { alpha, createTheme } from '@mui/material/styles'
import { GeistSans } from 'geist/font/sans'
import NextLink from 'next/link'
import { Ref, forwardRef } from 'react'
import { BACKGROUND_COLOR, PRIMARY_COLOR } from './colors'
import { playfairDisplay } from './fonts'

const LinkBehavior = forwardRef(function LinkBehavior(
  { href, ...props }: any,
  ref: Ref<HTMLAnchorElement>
) {
  return <NextLink href={href} ref={ref} {...props} />
})

const BASE_STATUS_COLORS = {
  confirmed: '#250405',
  declined: '#250405'
}

const FONT_WEIGHTS = {
  medium: 500,
  semibold: 600,
  bold: 700,
  extraBold: 800
}

export const CAPTION_FONT_SIZE = 12

const defaultTheme = createTheme()

export const theme = createTheme({
  palette: {
    primary: {
      main: PRIMARY_COLOR
    },
    error: {
      main: '#FF9900'
    },
    background: {
      default: BACKGROUND_COLOR,
      paper: '#fffbf7'
    },
    text: {
      primary: '#44080A',
      secondary: '#44080A'
    },
    processStatus: {
      confirmed: BASE_STATUS_COLORS.confirmed,
      declined: BASE_STATUS_COLORS.declined,
      pending: '#FFA800',
      personal: '#999494'
    },
    castStatus: {
      default: {
        accept: BASE_STATUS_COLORS.confirmed,
        decline: BASE_STATUS_COLORS.declined
      },
      pending: '#FFA800',
      accepted: '#00ACD1',
      confirmed: BASE_STATUS_COLORS.confirmed,
      declined: BASE_STATUS_COLORS.declined
    },
    ferman: {
      primary: '#157EFB',
      warning: '#FFA800',
      title: '#333231',
      titleLight: '#FFFFFF',
      titleTransparent: '#33323140',
      subTitle: '#000000B2',
      border: '#0000001A',
      borderDark: '#B8B4B3',
      borderLight: '#0000000A',
      iconDark: '#999794',
      success: '#49A600',
      successSolid: '#49A6001A',
      successTransparent: '#49A6004A',
      disabled: '#B7B4B1',
      disabledTransparent: '#EB4C5166',
      tour: '#33AAAA',
      tourLight: '#33AAAA00'
    }
  },
  fermanTableColumnSizes: {
    date: 105,
    name: 200,
    location: 190,
    staff: 'fit-content',
    staffItem: 450
  },
  fontSizes: {
    shortest: 10,
    short: 12,
    medium: 14,
    default: 16,
    large: 18,
    extraLarge: 20,
    header: 24
  },
  transitions: {
    duration: {
      primary: '0.3s'
    }
  },
  opacity: {
    solid: 1,
    opaque: 0.8,
    semiOpaque: 0.6,
    medium: 0.5,
    semiTransparent: 0.3,
    transparent: 0.1
  },
  zIndexes: {
    lowest: 100,
    low: 200,
    medium: 300,
    high: 400,
    higher: 500,
    highest: 600
  },
  borderRaduises: {
    underTiny: 1,
    tiny: 2,
    small: 4,
    regular: 6,
    moderate: 8,
    medium: 10,
    substantial: 12,
    considerable: 14,
    large: 16,
    expansive: 18,
    generous: 20,
    spacious: 22,
    oversized: 24,
    grand: 26,
    immense: 28,
    maximum: 30
  },
  shape: {
    borderRadius: 12
  },
  typography: {
    fontFamily: GeistSans.style.fontFamily,
    h1: {
      fontFamily: playfairDisplay.style.fontFamily,
      fontWeight: FONT_WEIGHTS.extraBold
    },
    h2: {
      fontFamily: playfairDisplay.style.fontFamily,
      fontWeight: FONT_WEIGHTS.extraBold,
      [defaultTheme.breakpoints.down('sm')]: {
        fontSize: '2.25rem'
      }
    },
    h3: {
      fontFamily: playfairDisplay.style.fontFamily,
      fontWeight: FONT_WEIGHTS.extraBold
    },
    h4: {
      fontFamily: playfairDisplay.style.fontFamily,
      fontWeight: FONT_WEIGHTS.extraBold,
      [defaultTheme.breakpoints.down('sm')]: {
        fontSize: '1.5rem'
      }
    },
    h5: {
      fontWeight: FONT_WEIGHTS.bold,
      [defaultTheme.breakpoints.down('sm')]: {
        fontSize: '1.25rem'
      }
    },
    h6: {
      fontWeight: FONT_WEIGHTS.bold,
      [defaultTheme.breakpoints.down('sm')]: {
        fontSize: '1rem'
      }
    },
    body1: {
      color: alpha('#44080A', 0.87),
      [defaultTheme.breakpoints.down('sm')]: {
        fontSize: '0.875rem'
      }
    },
    body2: {
      color: alpha('#44080A', 0.87),
      [defaultTheme.breakpoints.down('sm')]: {
        fontSize: '0.75rem'
      }
    },
    opacity: {
      high: 0.87,
      medium: 0.7,
      low: 0.6,
      disabled: 0.5
    }
  },
  components: {
    MuiLink: {
      styleOverrides: {
        root: {
          fontWeight: FONT_WEIGHTS.medium
        }
      },
      defaultProps: {
        component: LinkBehavior
      }
    },
    MuiButtonBase: {
      defaultProps: {
        LinkComponent: LinkBehavior
      }
    },

    MuiOutlinedInput: {
      defaultProps: {
        sx: {
          [defaultTheme.breakpoints.down('sm')]: {
            fontSize: '0.875rem'
          }
        }
      }
    },
    MuiInputLabel: {
      defaultProps: {
        sx: {
          [defaultTheme.breakpoints.down('sm')]: {
            fontSize: '0.875rem'
          }
        }
      }
    },
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: 'none',
          lineHeight: 1
        },
        sizeLarge: {
          padding: '24px 30px',
          fontSize: 16,
          [defaultTheme.breakpoints.down('sm')]: {
            padding: '21px 28px',
            fontSize: '0.875rem'
          }
        },
        sizeSmall: {
          padding: '14px 24px',
          fontSize: 14
        }
      }
    }
  }
})
