'use client'

import MuiGlobalStyles from '@mui/material/GlobalStyles'
import { css } from '@mui/material/styles'
import { FC } from 'react'
import { fonts } from 'styles/global'

export const GlobalStyles: FC = () => (
  <MuiGlobalStyles
    styles={css`
      ${fonts}
    `}
  />
)
